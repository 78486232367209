import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import Icon from '../images/whatapp.png'
import '../style/button.css'
import { Button } from '@mui/material';

const defaultTheme = createTheme();

class Contact extends React.Component {

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: '75vh'}}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url(https://images.unsplash.com/photo-1617042375876-a13e36732a04?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                opacity: '0.8',
              }}
            >

              <Typography component="h1" variant="h5" className='styletext'>
                SCRIVICI PER AVERE INFORMAZIONI
              </Typography>
              <Box component="form" onSubmit={this.sendEmail} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  variant="filled"
                  focused


                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Nome"
                  name="name"
                  variant="filled"
                  focused

                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="oggetto"
                  label="Oggetto"
                  name="oggetto"
                  variant="filled"
                  focused

                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="messaggio"
                  label="Messaggio"
                  type="text"
                  id="messaggio"
                  variant="filled"
                  multiline
                  rows={5}
                  focused


                />
                <div className='styletext'><a style={{ textDecoration: 'none', color: 'black' }} href='https://wa.me/+393421437909'>Contattaci anche con Whatsapp <img alt='Logo' src={Icon} style={{ width: '20px' }}></img></a></div>
                <Button size='large' type='submit' sx={{
                  color: 'white',
                  height: '55px',
                  width: '150px',
                  marginTop:'30px',
                  background: 'linear-gradient(90deg, rgba(0,152,255,1) 0%, rgba(133,21,167,0.8222748572085083) 100%)',

                  
                  '&:hover': {
                    boxShadow: '2px 2px 9px black',
                    borderBottom: '4px solid #8f00ff',





                  }
                }} >INVIA</Button>
              </Box>
            </Box>

          </Grid>
        </Grid>
      </ThemeProvider>
    );
  };
  sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm("service_9p3tcax", "template_c4etuxh", e.target, "bVTXbSRzIiymzhjzY")
      .then((result) => {
        toast.success("Email Inviata");
        console.log(result.text);
      }, (error) => {
        toast.error("Errore");
        console.log(error.text);
      });
    e.target.reset()
  }
}

export default Contact;
