import Header from '../components/header';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextSection from '../components/textsection';
import '../style/textsection.css'
import { Grid } from '@mui/material';
import Phone from '../images/sfondo.webp'
import Img1 from '../images/react.webp'
import Img2 from '../images/boostrap.webp'
import Img3 from '../images/js.webp'
import Img4 from '../images/mysql.webp'
import Img5 from '../images/firebase.webp'
import Img6 from '../images/htmlcss.webp'
import Card3d from '../components/card3d';
import CardServizi from '../components/card';
import Container from '@mui/material/Container';
import Image3D from '../components/3dimage';
import Contact from '../components/contact';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import '../style/presentation.css'
function Home() {
    function reveal() {
        var reveals = document.querySelectorAll(".containerd");
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }
    window.addEventListener("scroll", reveal);
    reveal();

    useEffect(() => {
        AOS.init({
            duration: 3000
        });
    }, []);



    return (
        <div className='container'>
            <ToastContainer />
            <Navbar />
            <div id='home'></div>
            <Header />

            <div className='colorsection1'>
                <TextSection
                    title='Trasforma la tua visione digitale in realtà.'
                    text='Offro soluzioni di sviluppo web su misura, progettate per garantire la migliore esperienza utente possibile nella creazione di un sito web.'
                />
            </div>

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={6}>
                    <div className='presentation'>
                        <div className="containerd">
                            <img alt='3D image' src={Phone}></img>
                            <img alt='3D image'src={Phone}></img>
                            <img alt='3D image'src={Phone}></img>
                            <img alt='3D image'src={Phone}></img>
                            <img alt='3D image'src={Phone}></img>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='textphone styletext' data-aos="fade-up-right" >Da concept a lancio, seguo il progetto passo dopo passo per garantirti il massimo.<br></br>Trasformo le tue idee in una presenza online di successo.</div>
                </Grid>
            </Grid>
            <div className='colorsection1' id='servizi'>
                <TextSection
                    title='SERVIZI'
                    text='Sono convinto che un sito web ben sviluppato è la chiave del successo per qualsiasi attività, grande o piccola che sia. Ecco perché mi dedico a fare il massimo per soddisfare le esigenze specifiche dei clienti, utilizzando le più recenti tecnologie nello sviluppo di siti web.'
                />
            </div>

            <Container sx={{ py: 12 }} className='cards'
            >
                <Grid container spacing={6}>
                    <CardServizi
                        frontImage="url('https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1364&q=80')"
                        title='Design'
                        text='Un design responsive e accessibile è fondamentale per garantire una buona esperienza utente su tutti i dispositivi. Il primo passo è creare la struttura e la grafica'
                    />
                    <CardServizi
                        frontImage="url('https://images.unsplash.com/photo-1615411836704-2c1636331bc2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80')"
                        title='Immagini'
                        text="La scelta delle immagini giuste è fondamentale per creare un sito web di successo. In base al concept del sito ricerco tramite siti specializzati immagini idonee"
                    />
                    <CardServizi
                        frontImage="url('https://images.unsplash.com/photo-1523001185760-84ed1a5c6377?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80')"
                        title='SEO'
                        text="Scrivo e ottimizzo il SEO per raggiungere una maggiore visibilità sui motori di ricerca e a farti notare dai tuoi potenziali clienti. Per questo servizio vengono utilizzati strumenti AI"
                    />
                    <CardServizi
                        frontImage="url('https://images.unsplash.com/photo-1531412501799-1b4c354ca957?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80')"
                        title='Cookie'
                        text='Implemento nel sito le fondamentali politiche di gestione dei cookie in linea con le normative europee vigenti, per garantire la massima trasparenza e rispetto della privacy.'
                    />
                    <CardServizi
                        frontImage="url('https://images.unsplash.com/photo-1551739440-5dd934d3a94a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1364&q=80')"
                        title='Dominio & Hosting'
                        text="Fornisco soluzioni di hosting e creazione del dominio affidabili e personalizzabili per soddisfare le esigenze di qualsiasi sito web."
                    />
                    <CardServizi
                        frontImage="url('https://images.unsplash.com/photo-1622782914767-404fb9ab3f57?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1364&q=80')"
                        title='Analytics'
                        text="L'analisi delle statistiche del sito web può fornire una comprensione più profonda del comportamento degli utenti. Offro servizi di analisi tramite piattaforme come Google Analytics"
                    />
                </Grid>
            </Container>
            <div id='tecnologie'></div>
            <div className='colorsection1'>
                <TextSection
                    title='TECNOLOGIE'
                    text="Le tecnologie adottate per lo sviluppo web influiscono sulla velocità, l'affidabilità e la scalabilità di un sito. L'utilizzo di tecnologie avanzate consente un'esperienza utente ottimale e unica per il sito web."
                />
            </div>

            <Container sx={{ py: 12 }} className='cards'
            >
                <Grid container spacing={6}>
                    <Image3D
                        image={Img1}
                        text='React è una libreria JavaScript utilizzata per la costruzione di interfacce utente dinamiche ed efficienti per siti web e applicazioni web.'
                        title='React'
                    />
                    <Image3D
                        image={Img6}
                        title='HTML CSS'
                        text='HTML e CSS sono linguaggi di programmazione utilizzati per la costruzione di siti web. HTML'
                    />
                    <Image3D
                        image={Img3}
                        title='JS'
                        text='JavaScript è un linguaggio di programmazione utilizzato per creare animazioni, effetti visivi, interazioni dinamiche '
                    />
                    <Image3D
                        image={Img4}
                        title='MYSQL'
                        text='MySQL è un sistema di gestione di database relazionali ampiamente utilizzato nella creazione di applicazioni web e siti web dinamici.'
                    />
                    <Image3D
                        image={Img5}
                        title='Firebase'
                        text="Firebase è una piattaforma di sviluppo mobile e web che offre una vasta gamma di funzionalità attraverso servizi cloud, come l'archiviazione dei dati in real-time o l'autenticazione degli utenti"

                    />
                    <Image3D
                        image={Img2}
                        title='BOOTSTRAP'
                        text='Bootstrap è un framework front-end di sviluppo web che permette di creare pagine web responsive'

                    />
                </Grid>
            </Container>
            <div className='colorsection1'>
                <TextSection
                    title='ASSISTENZA'
                    text="Non mi limito a sviluppare il sito web, ma offro anche supporto continuo e manutenzione per garantire il massimo rendimento a lungo termine per il tuo sito web."
                />
            </div>
            <Card3d/>
            <div className='colorsection1 margincard'>
            <div id='contact'></div>
                <TextSection
                    title='PREVENTIVO'
                    text="Vuoi saperne di più sui miei servizi? Contattaci per avere informazioni dettagliate e un preventivo personalizzato per la creazione del tuo sito web."
                />
            </div>
                <Contact />
            <Footer />
        </div>
    )


}
export default Home;