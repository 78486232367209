import { Grid } from '@mui/material';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import React from 'react';
import '../style/card.css'


function CardServizi(props) {
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);


    

    return (

        <Grid item xs={12} sm={6} md={4}>
            <div
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                <div class="box-container">
                    <div class="box-item">
                        <div class="flip-box">
                            <div class="flip-box-front text-center filter-"  style={{backgroundImage:`${props.frontImage}`}}>
                                <div class="inner color-white">
                                    <h3 class="flip-box-header">{props.title}</h3>
                                    <img src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="Freccia" class="flip-box-img" style={{width:'30px'}}></img>
                                </div>
                            </div>
                            <div class="flip-box-back text-center" style={{backgroundImage: "url('https://images.unsplash.com/photo-1608306448197-e83633f1261c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80')"}}>
                                <div class="inner color-white">
                                    <h3 class="flip-box-header ">{props.title}</h3>
                                    <p className='styletext'>{props.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Grid>
    )

}

export default CardServizi;