import { BrowserRouter as Router, Route, Routes } from "react-router-dom";import './App.css';
import Home from './pages/home'
import './App.css'

function App() {
  return (
      <div className="App">
          <Router>
            <Routes>
              <Route path='/' element={<Home />} />
            </Routes>
          </Router>
      </div>
  );
}

export default App;
