import '../style/cube.css'
import MenuIcon from '@mui/icons-material/Menu';
import Logo2 from '../images/logo4.png'
function Cube() {

    return (
        <div className="container" >
            <div id="wrapD3Cube">
                <div id="D3Cube">
                    <div id="side1">
                    </div>
                    <div id="center"><MenuIcon style={{color: '#9000ff', marginTop:'10px'}}/></div>
                    <div id="side1"></div>
                    <div id="side2"></div>
                    <div id="side3"></div>
                    <div id="side4"></div>
                    <div id="side5"></div>
                    <div id="side6"></div>
                </div>
            </div>
        </div >
    )
}

export default Cube;