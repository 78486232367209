import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';




function TextSection(props) {


    return (
        <Box
            sx={{
                pt: 8,
                pb: 6,
            }}

           
        >
            <Container maxWidth="sm">
                <Typography
                    component="h1"
                    variant="h2"
                    align="left"
                    color="white"
                    gutterBottom

                >
                    <div className='styletext titlesection'>
                    <bold>{props.title}</bold> 
                    </div>
                </Typography>
                <Typography align="left" color="white" paragraph>
                    <div className='styletext' >
                        {props.text}
                    </div>
                </Typography>
                <Stack
                    sx={{ pt: 4 }}
                    direction="row"
                    spacing={2}
                    justifyContent="left"
                >
                </Stack>
            </Container>
        </Box>






    )
}

export default TextSection