import '../style/image3d.css'
import { Grid } from '@mui/material';




function image3D(props) {



    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <div
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    style={{marginLeft:'12px'}}
                >
                    <figure class="snip1190">
                        <img src={props.image} alt="sample64" />
                        <figcaption>
                            <div class="square">
                                <div></div>
                            </div>
                            <h2><span>{props.title}</span></h2>
                            <p className='styletext'>{props.text}</p>
                        </figcaption>
                    </figure>
                </div>
            </Grid>
        </>
    )
}

export default image3D;